import { FORM_STATE } from "src/constants/campaign";
import { create } from "../";
import { CampaignFormValuesType } from "src/types/campaign";
import { produce } from "immer";
import { Product } from "src/types/product";

type queryData = {
    queryValue: any;
    queryResult: any;
    queryLogic: any;
};

type CampaignFormState = {
    formData: CampaignFormValuesType;
    setFormData: (data: CampaignFormValuesType) => void;
    clearForm: () => void;
    setStoreSelectedIndex: (data: { selectedIndex: number; stepsCompleted: number[] }) => void;
    setTypeData: (data: CampaignFormValuesType["type"]) => void;
    setDetailsData: (data: CampaignFormValuesType["steps"]["details"]["value"]) => void;
    setDuplicateUrlError: (data: boolean) => void;
    setPricingData: (data: queryData) => void;
    setAttributesData: (data: queryData) => void;
    setOutOfStock: (data: boolean) => void;
    setId: (data: number) => void;
    appendStaticProductsToProductsList: (products: Product[]) => void;
    appendDynamicProductsToProductsList: (staticProducts: Product[], dynamicProducts: Product[]) => void;
    updateProductsListOrder: (products: Product[]) => void;
    updateMainKeywords: (keywords: string[]) => void;
    updateInclusiveWords: (keywords: string[]) => void;
    updateExclusiveWords: (keywords: string[]) => void;
};

export const useCampaignFormStore = create<CampaignFormState>()((set) => {
    const initialState = {
        formData: FORM_STATE,
    };

    return {
        ...initialState,
        setFormData: (data) =>
            set(
                produce((draft) => {
                    draft.formData = data;
                })
            ),
        setDuplicateUrlError: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.details.value.duplicate_url = data;
                })
            ),
        setStoreSelectedIndex: (data) =>
            set(
                produce((draft) => {
                    draft.formData.selected_index = data.selectedIndex;
                    draft.formData.steps_completed = data.stepsCompleted;
                })
            ),
        setTypeData: (data) =>
            set(
                produce((draft) => {
                    draft.formData.type = data;
                })
            ),
        setDetailsData: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.details.value = data;
                })
            ),
        setPricingData: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.pricing_rules.value.queryValue = data.queryValue;
                    draft.formData.steps.pricing_rules.value.queryResult = data.queryResult;
                    draft.formData.steps.pricing_rules.value.queryLogic = data.queryLogic;
                })
            ),
        setAttributesData: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.attributes.value.queryValue = data.queryValue;
                    draft.formData.steps.attributes.value.queryResult = data.queryResult;
                    draft.formData.steps.attributes.value.queryLogic = data.queryLogic;
                })
            ),
        appendStaticProductsToProductsList: (data) =>
            set(
                produce((draft) => {
                    const products = draft.formData.steps.products.value;
                    const indexToInsertBefore = products.findIndex(
                        (product: Product) => product.isDynamic || product.isHidden
                    );

                    if (indexToInsertBefore === -1) {
                        // If there are no hidden products, append the new data at the end
                        products.push(...data);
                    } else {
                        // Insert the new data before the first hidden product
                        products.splice(indexToInsertBefore, 0, ...data);
                    }
                })
            ),
        appendDynamicProductsToProductsList: (staticProducts, dynamicProducts) =>
            set(
                produce((draft) => {
                    const products = staticProducts;
                    const uniqueIds = new Set(products.map((product) => product.id));

                    for (const newProduct of dynamicProducts) {
                        if (!uniqueIds.has(newProduct.id)) {
                            // If the product's id is unique, add it to the staticProducts array
                            products.push(newProduct);
                            uniqueIds.add(newProduct.id);
                        }
                    }
                    draft.formData.steps.products.value = products;
                })
            ),
        updateProductsListOrder: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.products.value = data;
                })
            ),
        updateMainKeywords: (data) =>
            set(
                produce((draft) => {
                    if (data) {
                        draft.formData.steps.products.mainKeywords = data.join(" ");
                    }
                })
            ),
        updateInclusiveWords: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.products.inclusiveWords = data;
                })
            ),
        updateExclusiveWords: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.products.exclusiveWords = data;
                })
            ),
        setOutOfStock: (data) =>
            set(
                produce((draft) => {
                    draft.formData.steps.pricing_rules.value.out_of_stock = data;
                })
            ),
        clearForm: () => set(initialState),
        setId: (data) =>
            set(
                produce((draft) => {
                    draft.formData.id = data;
                })
            ),
    };
});
