import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { useUiStore } from "src/store/ui";
import CloseIcon from "@mui/icons-material/Close";

const SideDrawer = () => {
    const DrawerStatus = useUiStore((state) => state.showDrawer);
    const setDrawer = useUiStore((state) => state.setDrawer);

    const drawerTitle = useUiStore((state) => state.drawerTitle);
    const drawerContent = useUiStore((state) => state.drawerContent);

    const closeDrawer = () => {
        setDrawer(false);
    };

    return (
        <Drawer
            anchor={"right"}
            open={DrawerStatus}
            onClose={closeDrawer}
            sx={{
                "& .MuiDrawer-paper": {
                    width: { sm: 1, md: "auto" },
                    alignItems: "center",
                    backgroundColor: "#f9fafb",
                },
            }}
        >
            <Stack
                padding={1}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                    width: "100%",
                    background: "linear-gradient(180deg, rgba(249, 250, 251, 0), rgba(249, 250, 251, 1) 100%)",
                }}
                alignItems={"center"}
            >
                <Typography
                    variant='subtitle1'
                    px={2}
                >
                    {drawerTitle}
                </Typography>
                <IconButton onClick={closeDrawer}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            {drawerContent}
        </Drawer>
    );
};

export default SideDrawer;
