import { useState, type FC, type ReactNode } from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import { RouterLink } from "src/components/router-link";
import { ActionPopper } from "src/components/popper";
import SubSideNavMenu from "./sub-side-nav-menu";
import { Item } from "../config";
interface SideNavItemProps {
    item?: Item;
    active?: boolean;
    children?: ReactNode;
    depth?: number;
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    label?: ReactNode;
    path?: string;
    title: string;
    disabledMessage?: string;
    desktopNavOpen: () => void;
}

export const SideNavItem: FC<SideNavItemProps> = (props) => {
    const { item, active, depth = 0, disabled, external, icon, path, title, desktopNavOpen } = props;

    // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

    let startIcon: ReactNode;

    if (depth === 0) {
        startIcon = icon;
    }

    const offset = depth === 0 ? 0 : (depth - 1) * 16;

    const linkProps = path
        ? external
            ? {
                  component: "a",
                  href: path,
                  target: "_blank",
              }
            : {
                  component: RouterLink,
                  href: path,
              }
        : {};

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const onMouseEnter = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { currentTarget } = evt;
        setAnchorEl(currentTarget);
    };

    const onMouseLeave = () => {
        setAnchorEl(null);
    };

    return (
        <li>
            <ButtonBase
                disabled={disabled}
                sx={{
                    alignItems: "center",
                    borderRadius: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    width: "100%",
                    flexDirection: "column",
                }}
                onClick={desktopNavOpen}
                {...linkProps}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {startIcon && (
                    <Box
                        component='span'
                        sx={{
                            alignItems: "center",
                            color: "var(--nav-item-icon-color)",
                            display: "inline-flex",
                            justifyContent: "center",
                            pl: `${16 + offset}px`,
                            pr: "16px",
                            py: "6px",
                            borderRadius: 3,
                            ...(disabled && {
                                color: "var(--nav-item-disabled-color)",
                            }),
                            ...(active && {
                                color: "var(--nav-item-icon-active-color)",
                            }),
                            ...(active && {
                                ...(depth === 0 && {
                                    backgroundColor: "var(--nav-item-active-bg)",
                                }),
                            }),
                            "&:hover": {
                                backgroundColor: "var(--nav-item-hover-bg)",
                            },
                        }}
                    >
                        {startIcon}
                    </Box>
                )}
                <Box
                    component='span'
                    sx={{
                        color: "var(--nav-item-color)",
                        flexGrow: 1,
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: ".70rem",
                        fontWeight: depth > 0 ? 500 : 600,
                        lineHeight: "24px",
                        whiteSpace: "nowrap",
                        ...(active && {
                            color: "var(--nav-item-active-color)",
                        }),
                        ...(disabled && {
                            color: "var(--nav-item-disabled-color)",
                        }),
                    }}
                >
                    {title}
                </Box>
                <ActionPopper
                    open={open}
                    anchorEl={anchorEl}
                    placement='right-start'
                    backgroundColor='#fff'
                    sx={{
                        boxShadow: "0px 2px 4px 2px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <SubSideNavMenu
                        open={open}
                        items={item?.items}
                    />
                </ActionPopper>
            </ButtonBase>
        </li>
    );
};
