import { useCallback, useRef, useState } from 'react';

export function useDialog() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleConfirm = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    anchorRef,
    handleConfirm,
    handleCancel,
    handleOpen,
    open
  };
}