import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { FC } from "react";
import type { Theme } from "@mui/material/styles/createTheme";
import { Avatar, Box, Button, IconButton, Stack, SvgIcon, useMediaQuery } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "src/layouts/dashboard/account-button";
import { TeamPopover } from "../team-button";
import { useAuth } from "src/hooks/use-auth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BreadcrumbsNav from "./bread-crumbs-nav";
import LuxLogo from "../logos/lux-logo";

const TOP_NAV_HEIGHT = 64;

interface TopNavProps {
    onNavOpen?: () => void;
}

export const TopNav: FC<TopNavProps> = ({ onNavOpen }) => {
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
    const accountPopover = usePopover();
    const teamPopover = usePopover();
    const auth = useAuth();

    return (
        <>
            <Box
                component='header'
                sx={{
                    position: "sticky",
                    zIndex: (theme) => theme.zIndex.appBar,
                }}
            >
                {/* Add the dummy Nav block */}
                {lgUp && (
                    <Stack
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "80px",
                            height: "65px",
                            zIndex: -1,
                            background: "#f7be29",
                        }}
                    ></Stack>
                )}
                <Stack
                    alignItems='center'
                    direction='row'
                    justifyContent='space-between'
                    spacing={2}
                    sx={{
                        minHeight: TOP_NAV_HEIGHT,
                        paddingLeft: lgUp ? "10px" : 2,
                        paddingRight: 2,
                    }}
                >
                    <Stack
                        alignItems='center'
                        direction='row'
                        spacing={2}
                    >
                        {!lgUp && (
                            <IconButton onClick={onNavOpen}>
                                <SvgIcon fontSize='medium'>
                                    <MenuIcon />
                                </SvgIcon>
                            </IconButton>
                        )}
                        {lgUp && (
                            <Stack
                                sx={{
                                    flexGrow: 1,
                                    top: "auto",
                                    display: "flex",
                                    height: "60px",
                                    pt: 1,
                                }}
                            >
                                <LuxLogo square={true} />
                            </Stack>
                        )}
                        <Stack>
                            <Stack
                                direction='row'
                                px={2}
                            >
                                <BreadcrumbsNav />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        alignItems='center'
                        direction='row'
                        spacing={2}
                    >
                        {auth.selectedClient && (
                            <Button
                                ref={teamPopover.anchorRef}
                                onClick={teamPopover.handleOpen}
                                endIcon={<ArrowDropDownIcon />}
                            >
                                {auth.selectedClient?.name}
                            </Button>
                        )}
                        <Avatar
                            onClick={accountPopover.handleOpen}
                            ref={accountPopover.anchorRef}
                            sx={{
                                cursor: "pointer",
                                height: 40,
                                width: 40,
                            }}
                        />
                    </Stack>
                </Stack>
            </Box>
            <TeamPopover
                anchorEl={teamPopover.anchorRef.current}
                open={teamPopover.open}
                onClose={teamPopover.handleClose}
            />
            <AccountPopover
                anchorEl={accountPopover.anchorRef.current}
                open={accountPopover.open}
                onClose={accountPopover.handleClose}
            />
        </>
    );
};

TopNav.propTypes = {
    onNavOpen: PropTypes.func,
};
