import axiosInstance from "../axios-config";
import type { User, UserClient } from "src/types/user";
import { ACCESS_TOKEN_KEY } from "src/contexts";
import * as appConstants from "src/constants";

type SignInRequest = {
    email: string;
    password: string;
};

type SignInResponse = Promise<{
    accessToken: string;
}>;

type ForgotPasswordRequest = {
    email: string;
};

type ForgotPasswordResponse = Promise<{
    message: string;
}>;

type ResetPasswordRequest = {
    email: string;
    password: string;
    confirmPassword: string;
    token: string;
};

type ResetasswordResponse = Promise<{
    message: string;
}>;

type NonceTokenResponse = Promise<{
    nonce: {
        _nonce: string;
    };
}>;

type SignOutResponse = Promise<{
    message: string;
}>;

type MeResponse = Promise<User>;

interface UserResponse {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
    active: number;
    is_admin: number;
    group_id: number;
    image_uri: string;
}

type getClientListResponse = Promise<UserClient[]>;

type RefreshTokenResponse = Promise<{
    accessToken: string;
}>;

class AuthApi {
    async signIn(request: SignInRequest): SignInResponse {
        const { email, password } = request;

        return new Promise(async (resolve, reject) => {
            try {
                const data = JSON.stringify({
                    email: email,
                    password: password,
                });

                const config = {
                    method: "post",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/auth/login",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    data: data,
                };

                const response = await axiosInstance(config);
                if (response.status === 200 && response.data && response.data.user_info && response.data.access_token) {
                    // Create the access token
                    resolve({
                        accessToken: response.data.access_token,
                    });
                } else {
                    console.error("[Auth Api]: ", "Please check your email and password");
                    reject(new Error("Please check your email and password"));
                }
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("Please check your email and password"));
            }
        });
    }

    async signOut(): SignOutResponse {
        return new Promise(async (resolve, reject) => {
            try {
                const config = {
                    method: "post",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/auth/logout",
                };

                const response = await axiosInstance(config);
                const { message } = response.data;

                if (!message || message !== "Successfully logged out") {
                    reject(new Error("Invalid authorization token"));
                    return;
                }

                resolve({
                    message,
                });
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("Internal server error"));
            }
        });
    }

    async me(): MeResponse {
        return new Promise(async (resolve, reject) => {
            try {
                const config = {
                    method: "post",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/auth/me",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                };

                const response = await axiosInstance(config);
                const { id, active, username, email, group_id, is_admin } = response.data as UserResponse;

                if (!id) {
                    reject(new Error("Invalid authorization token"));
                    return;
                } else if (!active) {
                    reject(new Error("Inactive user"));
                    return;
                }

                // TODO: This is a temporary solution to check if the user is a Lontailux user and show work inprogress page
                const isLuxAdmin = group_id === 1 && is_admin === 1;

                resolve({
                    id: id,
                    email: email,
                    username: username,
                    isLuxAdmin,
                });
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("Internal server error"));
            }
        });
    }

    async getClientList(): getClientListResponse {
        return new Promise(async (resolve, reject) => {
            try {
                const config = {
                    method: "get",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/get-clients",
                };
                const response = await axiosInstance(config);

                const resObj = response.data.data;

                const transformedData: UserClient[] = Object.keys(resObj).reduce(
                    (result: UserClient[], clientUsername) => {
                        const clientData = resObj[clientUsername];
                        const campaignDomain = clientData.domains.find((domain: any) => domain.type_id === 4);

                        // Check if there are any domains with type_id == 1 (organic) or 4 (campaign)
                        const relevantDomains = clientData.domains.filter(
                            (domain: any) => domain.type_id === 1 || domain.type_id === 4
                        );
                        if (relevantDomains.length > 0) {
                            // Only push the client if there are relevant domains
                            result.push({
                                clientUsername,
                                url: clientData.url,
                                name: clientData.name,
                                logo: clientData.logo,
                                campaignDomain: campaignDomain?.domain,
                                domains: relevantDomains.map((domain: any) => domain.type_id), // Mapping only the relevant domains
                            });
                        }
                        return result;
                    },
                    []
                );

                resolve(transformedData);
            } catch (err) {
                console.error("[Auth Api - get-clients]: ", err);
                reject(new Error("Internal server error"));
            }
        });
    }

    async forgotPassword(request: ForgotPasswordRequest): ForgotPasswordResponse {
        const { email } = request;
        return new Promise(async (resolve, reject) => {
            try {
                const _nonce = await this.requestNonceToken();
                const data = JSON.stringify({
                    email: email,
                    ..._nonce,
                });
                const config = {
                    method: "post",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/password/email",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    data: data,
                };

                const response = await axiosInstance(config);

                if (response.status === 200 && response.data && response.data.message == "passwords.sent") {
                    resolve(response.data.message);
                } else if (
                    response.status === 200 &&
                    response.data &&
                    response.data.status === "error" &&
                    response.data.message
                ) {
                    reject(new Error(response.data.message));
                } else {
                    reject(new Error("Please check your email nonce"));
                }
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("Please check your email nonce"));
            }
        });
    }

    async resetPassword(request: ResetPasswordRequest): ResetasswordResponse {
        const { email, password, confirmPassword, token } = request;
        return new Promise(async (resolve, reject) => {
            try {
                const _nonce = await this.requestNonceToken();
                const data = JSON.stringify({
                    email,
                    password,
                    password_confirmation: confirmPassword,
                    token,
                    ..._nonce,
                });

                const config = {
                    method: "post",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/password/reset",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    data: data,
                };

                const response = await axiosInstance(config);

                if (response.status === 200 && response.data && response.data.message) {
                    resolve(response.data.message);
                } else if (
                    response.status === 200 &&
                    response.data &&
                    response.data.status === "error" &&
                    response.data.message
                ) {
                    reject(new Error(response.data.message));
                } else {
                    reject(new Error("Please check your email nonce"));
                }
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("Please check your email nonce"));
            }
        });
    }

    async requestNonceToken(): NonceTokenResponse {
        return new Promise(async (resolve, reject) => {
            try {
                const config = {
                    method: "GET",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/request_token",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                };
                const response = await axiosInstance(config);
                if (response.status === 200 && response.data && response.data.data && response.data.data._nonce) {
                    const _nonce = response.data.data;
                    resolve(_nonce);
                } else {
                    console.error("[Auth Api]: ", "nonce");
                    reject(new Error("server error"));
                }
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("server error"));
            }
        });
    }

    async refreshToken(): RefreshTokenResponse {
        return new Promise(async (resolve, reject) => {
            try {
                const config = {
                    method: "POST",
                    url: appConstants.VITE_APP_API_BASE_URL + "/api/auth/refresh",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                };
                const response = await axiosInstance(config);
                if (response.status === 200 && response.data && response.data.access_token) {
                    const accessToken = response.data.access_token;
                    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
                    resolve({
                        accessToken: accessToken,
                    });
                } else {
                    reject(new Error("server error"));
                }
            } catch (err) {
                console.error("[Auth Api]: ", err);
                reject(new Error("server error"));
            }
        });
    }
}

export const authApi = new AuthApi();
