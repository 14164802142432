import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FC } from "react";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="body2" sx={{ fontWeight: '500' }}>{title}</DialogTitle>
      <DialogContent sx={{ maxWidth: '400px' }}>
        <DialogContentText variant="body2">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelButtonText || 'Cancel'}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          {confirmButtonText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog