import {
    createTheme,
    filledInputClasses,
    inputLabelClasses,
    outlinedInputClasses,
    paperClasses,
    tableCellClasses,
} from "@mui/material";
import type { PaletteColor, PaletteOptions } from "@mui/material/styles/createPalette";
import type { Components } from "@mui/material/styles/components";

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        contrast: true;
    }
}

// Used only to create transitions
const muiTheme = createTheme();

interface Config {
    palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => {
    return {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 600,
                    letterSpacing: 0,
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "contrast" },
                    style: {
                        background: "#F2F3F7",
                        color: (palette.primary as PaletteColor).main,
                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                        "&:hover": {
                            background: "#F2F3F7CC",
                            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3)",
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    borderRadius: "25px",
                    textTransform: "none",
                    "&.Mui-disabled.MuiButton-containedPrimary": {
                        cursor: "not-allowed",
                        backgroundColor: "rgba(29, 27, 32, 0.12)",
                        color: "rgba(27, 27, 31, 0.38)",
                        border: "none",
                    },
                },
                sizeSmall: {
                    padding: "6px 16px",
                },
                sizeMedium: {
                    padding: "8px 20px",
                },
                sizeLarge: {
                    padding: "11px 24px",
                },
                textSizeSmall: {
                    padding: "7px 12px",
                },
                textSizeMedium: {
                    padding: "9px 16px",
                },
                textSizeLarge: {
                    padding: "12px 16px",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    [`&.${paperClasses.elevation1}`]: {
                        boxShadow: "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "32px 24px",
                    "&:last-child": {
                        paddingBottom: "32px",
                    },
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: "h6",
                },
                subheaderTypographyProps: {
                    variant: "body2",
                },
            },
            styleOverrides: {
                root: {
                    padding: "32px 24px 16px",
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "*": {
                    boxSizing: "border-box",
                },
                html: {
                    MozOsxFontSmoothing: "grayscale",
                    WebkitFontSmoothing: "antialiased",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%",
                },
                body: {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%",
                },
                "#root, #__next": {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                },
                "#nprogress": {
                    pointerEvents: "none",
                },
                "#nprogress .bar": {
                    height: 3,
                    left: 0,
                    position: "fixed",
                    width: "100%",
                    zIndex: 2000,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    "&::placeholder": {
                        opacity: 1,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: "24px",
                    "&::placeholder": {
                        color: palette.text!.secondary,
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 54,
                    height: 30,
                    padding: 0,
                    margin: 8,
                    overflow: "unset",
                },
                switchBase: {
                    padding: 4,
                    "&.Mui-checked": {
                        transform: "translateX(24px)",
                        color: "#fff",
                        "& + .MuiSwitch-track": {
                            opacity: 1,
                            background: "none",
                            backgroundColor: (palette.primary as PaletteColor).main,
                            borderColor: (palette.primary as PaletteColor).main,
                        },

                        "& .MuiSwitch-thumb": {
                            width: 22,
                            height: 22,
                            marginTop: 0,
                            backgroundColor: "#fff",
                        },
                    },
                    "&.Mui-disabled": {
                        color: palette.action!.disabled,
                    },
                },
                thumb: {
                    width: 16,
                    height: 16,
                    backgroundColor: "#45464F",
                    boxShadow: "none",
                    marginTop: 3, // thumb height / 2
                },
                track: {
                    borderRadius: 30 / 2,
                    opacity: 0.6,
                    backgroundColor: "#36343B",
                    boxSizing: "border-box",
                    border: "2px solid #938F99",
                },
                checked: {},
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                    padding: 0,
                    borderRadius: 8,
                    borderStyle: "solid",
                    borderWidth: 1,
                    overflow: "hidden",
                    borderColor: palette.neutral![200],
                    transition: muiTheme.transitions.create(["border-color", "box-shadow"]),
                    "&:hover": {
                        backgroundColor: palette.action!.hover,
                    },
                    "&:before": {
                        display: "none",
                    },
                    "&:after": {
                        display: "none",
                    },
                    [`&.${filledInputClasses.disabled}`]: {
                        backgroundColor: "transparent",
                    },
                    [`&.${filledInputClasses.focused}`]: {
                        backgroundColor: "transparent",
                        borderColor: (palette.primary as PaletteColor).main,
                        // boxShadow: `${(palette.primary as PaletteColor).main} 0 0 0 2px`
                    },
                    [`&.${filledInputClasses.error}`]: {
                        borderColor: palette.error as PaletteColor,
                        // boxShadow: `${(palette.error as PaletteColor)} 0 0 0 2px`
                    },
                },
                input: {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: "24px",
                    padding: "16.5px 14px",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: palette.action!.hover,
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: palette.neutral![200],
                        },
                    },
                    [`&.${outlinedInputClasses.focused}`]: {
                        backgroundColor: "transparent",
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: (palette.primary as PaletteColor).main,
                            // boxShadow: `${(palette.primary as PaletteColor).main} 0 0 0 2px`
                        },
                    },
                    [`&.${filledInputClasses.error}`]: {
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: palette.error as PaletteColor,
                            // boxShadow: `${(palette.error as PaletteColor)} 0 0 0 2px`
                        },
                    },
                },
                input: {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: "24px",
                },
                notchedOutline: {
                    borderColor: palette.neutral![200],
                    transition: muiTheme.transitions.create(["border-color", "box-shadow"]),
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 500,
                    [`&.${inputLabelClasses.filled}`]: {
                        transform: "translate(12px, 18px) scale(1)",
                    },
                    [`&.${inputLabelClasses.shrink}`]: {
                        [`&.${inputLabelClasses.standard}`]: {
                            transform: "translate(0, -1.5px) scale(0.85)",
                        },
                        [`&.${inputLabelClasses.filled}`]: {
                            transform: "translate(12px, 6px) scale(0.85)",
                        },
                        [`&.${inputLabelClasses.outlined}`]: {
                            transform: "translate(14px, -9px) scale(0.85)",
                        },
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: "20px",
                    textTransform: "none",
                    minWidth: "180px",
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    cursor: "pointer",
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "rgba(238,238,238)",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: palette.divider,
                    padding: "15px 16px",
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                        backgroundColor: palette.neutral![50],
                        color: palette.neutral![700],
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: 1,
                        letterSpacing: 0.5,
                        textTransform: "capitalize",
                    },
                    [`& .${tableCellClasses.paddingCheckbox}`]: {
                        paddingTop: 4,
                        paddingBottom: 4,
                    },
                },
            },
        },
        // MuiTextField: {
        //   defaultProps: {
        //     variant: 'filled'
        //   }
        // },
        MuiChip: {
            styleOverrides: {
                root: {
                    margin: "4px!important",
                },
            },
        },
        MuiImageListItemBar: {
            styleOverrides: {
                titleWrap: {
                    padding: "4px",
                },
            },
        },
    };
};
