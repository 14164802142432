import type { Product, ProductApi } from "./product";

type Nullable<T> = T | null;
export interface Campaign {
    id: number;
    name: string;
    campaign_name?: string;
    type: CampaignTypes;
    status: CampaignStatus;
    url: string;
    dateCreated: string;
    dateUpdated: string;
    dateStart: string;
    dateEnd: string;
    tags: string[];
}

type CampaignStatus = CampaignStatusType.STARTED | CampaignStatusType.ARCHIVED | CampaignStatusType.DRAFT;

export enum CampaignStatusType {
    STARTED = "Started",
    ARCHIVED = "Archived",
    DRAFT = "Draft",
}

export enum CampaignApiStatus {
    DRAFT = 2,
    STARTED = 1,
    ARCHIVED = 6,
    NOT_STARTED = 5,
}

type CategoryType = {
    category_id: number;
    category_name: string;
};

export type CategoriesType = {
    categories: CategoryType[];
};

export enum CampaignTypes {
    Dynamic = "dynamic",
    Static = "static",
}

export type pricingRules = {
    id: string;
    type: string;
};

export interface CampaignFormDetails {
    name: string;
    pageTitle: string;
    url: string;
    start_date: Date | null;
    end_date: Date | null;
    on_going: boolean;
    tags: string[];
    id?: number | null;
    category_id: number | null;
    category_name: string | null;
    duplicate_url: boolean;
}

export interface TypeOfCampaignFormType {
    type: CampaignTypes | string;
}

export interface CampaignApiValuesType {
    iserp_id: number;
    item_listing_type: CampaignTypes;
    iserp_name: string;
    page_title: string;
    campaign_name: string;
    iserp_url: string;
    iserp_slug: string;
    category_id: Nullable<number>;
    category_name: string;
    schedule_start_date: Nullable<Date>;
    schedule_end_date: Nullable<Date>;
    user_preference_on_items: string;
    preprocessing_rules: string;
    display_rules: string;
    iserp_tags: string;
    iserp_synonyms: string;
    iserp_negatives: string;
}
export type QueryValue = {
    queryValue: string | null | undefined;
    queryResult: string | null | undefined;
    queryLogic: string | null | undefined;
};

export type PricingValue = QueryValue & { out_of_stock: boolean };

export interface CampaignFormValuesType {
    id: number | null;
    selected_index: number;
    steps_completed: number[];
    type: CampaignTypes;
    steps: {
        details: {
            value: CampaignFormDetails;
            conditions: {
                url_manual_entry: boolean;
            };
        };
        attributes: {
            value: QueryValue;
        };
        pricing_rules: {
            value: PricingValue;
        };
        products: {
            mainKeywords: string | null | undefined;
            inclusiveWords: string[] | null | undefined;
            exclusiveWords: string[] | null | undefined;
            value: Product[];
        };
    };
}

export interface CampaignEditPostDetails {
    status?: string;
    type: CampaignTypes | null;
    longtail_keyword: string; // iserp_name
    campaign_name: string;
    page_title: string;
    url: string;
    start_date: string | null;
    end_date: string | null;
    tags: string[];
    id?: number | null;
    category_id: number | null;
    attributes_filter: {};
    pricing_query: {};
    products_list?: ProductApi[];
    inclusive_words: string[] | null | undefined;
    exclusive_words: string[] | null | undefined;
}
