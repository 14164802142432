import './App.css';
import './global.css';

import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { routes } from '../src/routes/index';
import { AuthConsumer, AuthProvider } from '../src/contexts/auth/index';
import { createTheme } from '../src/theme/index';
import { SnackbarProvider } from 'src/components/snackbar';
import { initDatadog, initHotjar } from './utils/tools';


// Create a client
const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      },
    },
  }
);

export const App: FC = () => {
  const element = useRoutes(routes);
  const SplashScreen = () => null;
  const theme = createTheme();

  useEffect(() => {
    initHotjar();
    initDatadog();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <AuthConsumer>
          {(auth) => {
            if (auth.isInitialized) {
              if (auth.isAuthenticated) {
                auth.startTokenRefreshInterval();
              } else {
                auth.clearTokenRefreshInterval();
              }
            }

            return !auth.isInitialized ? (
              <SplashScreen />
            ) : (
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <QueryClientProvider client={queryClient}>
                  <SnackbarProvider>
                    {element}
                  </SnackbarProvider>
                </QueryClientProvider>
              </ThemeProvider>
            );
          }}
        </AuthConsumer>
      </AuthProvider>
    </LocalizationProvider>
  );
};
