import { createContext } from "react";

import type { User, UserClient } from "src/types/user";

export interface State {
	isInitialized: boolean;
	isAuthenticated: boolean;
	user: User | null;
	clientList: UserClient[] | null;
	selectedClient: UserClient | null;
}

export const initialState: State = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
	clientList: null,
	selectedClient: null,
};

export interface AuthContextType extends State {
	issuer: "JWT";
	signIn: (email: string, password: string) => Promise<void>;
	signOut: () => Promise<void>;
	changeClient: (userClient: UserClient) => void;
	forgotPassword: (email: string) => Promise<void>;
	resetPassword: (
		email: string,
		password: string,
		confirmPassword: string,
		token: string
	) => Promise<void>;
	startTokenRefreshInterval: () => Promise<void>;
	clearTokenRefreshInterval: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
	...initialState,
	issuer: "JWT",
	signIn: () => Promise.resolve(),
	signOut: () => Promise.resolve(),
	changeClient: (userClient: UserClient) => {},
	forgotPassword: () => Promise.resolve(),
	resetPassword: (status) => Promise.resolve(),
	startTokenRefreshInterval: () => Promise.resolve(),
	clearTokenRefreshInterval: () =>  Promise.resolve(),
});

export const AuthConsumer = AuthContext.Consumer;
