import { useSelectedClient } from "./use-selected-client"; // Adjust the import path according to your project structure

// Custom hook to check page validity
export const usePageValidity = () => {
    const selectedClient = useSelectedClient();

    // Logic to determine if the campaigns page is valid
    const campaignsPageValid = selectedClient?.domains?.indexOf(4) > -1 ?? false;

    // Logic to determine if the organic page is valid
    const organicPageValid = [1].some((val) => selectedClient?.domains?.includes(val));

    return { campaignsPageValid, organicPageValid };
};
