// SnackbarContext.tsx
import React, { createContext, useContext, useState, useCallback, ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

interface SnackbarContextType {
    showMessage: (message: string, severity?: AlertColor) => void;
}

const SnackbarContext = createContext<SnackbarContextType>({
    showMessage: () => {},
});

export const useSnackbarContext = () => useContext(SnackbarContext);

interface SnackbarProviderProps {
    children: ReactNode;
}

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    [`&.MuiSnackbar-root.MuiSnackbar-root`]: {
        bottom: "100px",
    },
}));

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        message: "",
        severity: "info" as AlertColor,
    });

    const showMessage = useCallback((message: string, severity: AlertColor = "info") => {
        setSnackbarState({ open: true, message, severity });
    }, []);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarState((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <SnackbarContext.Provider value={{ showMessage }}>
            {children}
            <StyledSnackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert
                    variant='filled'
                    onClose={handleClose}
                    severity={snackbarState.severity}
                    sx={{
                        width: "100%",
                        color: "white",
                    }}
                >
                    {snackbarState.message}
                </Alert>
            </StyledSnackbar>
        </SnackbarContext.Provider>
    );
};
