import type { FC } from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { usePathname } from "src/hooks/use-pathname";
import type { NavColor } from "src/types/settings";
import type { Section } from "../config";
import { SideNavSection } from "./side-nav-section";
import Box from "@mui/material/Box";

const SIDE_NAV_WIDTH = 80;

const useCssVars = (color: NavColor): Record<string, string> => {
    const theme = useTheme();
    return useMemo((): Record<string, string> => {
        switch (color) {
            case "blend-in":
                if (theme.palette.mode === "dark") {
                    return {
                        "--nav-bg": theme.palette.background.default,
                        "--nav-color": theme.palette.neutral[100],
                        "--nav-border-color": theme.palette.neutral[700],
                        "--nav-logo-border": theme.palette.neutral[700],
                        "--nav-section-title-color": theme.palette.neutral[400],
                        "--nav-item-color": theme.palette.neutral[400],
                        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
                        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
                        "--nav-item-active-color": theme.palette.text.primary,
                        "--nav-item-disabled-color": theme.palette.neutral[600],
                        "--nav-item-icon-color": theme.palette.neutral[500],
                        "--nav-item-icon-active-color": theme.palette.primary.main,
                        "--nav-item-icon-disabled-color": theme.palette.neutral[700],
                        "--nav-item-chevron-color": theme.palette.neutral[700],
                        "--nav-scrollbar-color": theme.palette.neutral[400],
                    };
                } else {
                    return {
                        "--nav-bg": theme.palette.background.default,
                        "--nav-color": theme.palette.text.primary,
                        "--nav-border-color": theme.palette.neutral[100],
                        "--nav-logo-border": theme.palette.neutral[100],
                        "--nav-section-title-color": theme.palette.neutral[400],
                        "--nav-item-color": theme.palette.text.secondary,
                        "--nav-item-hover-bg": theme.palette.action.hover,
                        "--nav-item-active-bg": theme.palette.action.selected,
                        "--nav-item-active-color": theme.palette.text.primary,
                        "--nav-item-disabled-color": theme.palette.neutral[400],
                        "--nav-item-icon-color": theme.palette.neutral[400],
                        "--nav-item-icon-active-color": theme.palette.primary.main,
                        "--nav-item-icon-disabled-color": theme.palette.neutral[400],
                        "--nav-item-chevron-color": theme.palette.neutral[400],
                        "--nav-scrollbar-color": theme.palette.neutral[900],
                    };
                }

            case "discrete":
                if (theme.palette.mode === "dark") {
                    return {
                        "--nav-bg": theme.palette.neutral[900],
                        "--nav-color": theme.palette.neutral[100],
                        "--nav-border-color": theme.palette.neutral[700],
                        "--nav-logo-border": theme.palette.neutral[700],
                        "--nav-section-title-color": theme.palette.neutral[400],
                        "--nav-item-color": theme.palette.neutral[400],
                        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
                        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
                        "--nav-item-active-color": theme.palette.text.primary,
                        "--nav-item-disabled-color": theme.palette.neutral[600],
                        "--nav-item-icon-color": theme.palette.neutral[500],
                        "--nav-item-icon-active-color": theme.palette.primary.main,
                        "--nav-item-icon-disabled-color": theme.palette.neutral[700],
                        "--nav-item-chevron-color": theme.palette.neutral[700],
                        "--nav-scrollbar-color": theme.palette.neutral[400],
                    };
                } else {
                    return {
                        "--nav-bg": theme.palette.neutral[50],
                        "--nav-color": theme.palette.text.primary,
                        "--nav-border-color": theme.palette.divider,
                        "--nav-logo-border": theme.palette.neutral[200],
                        "--nav-section-title-color": theme.palette.neutral[500],
                        "--nav-item-color": theme.palette.neutral[500],
                        "--nav-item-hover-bg": theme.palette.action.hover,
                        "--nav-item-active-bg": theme.palette.action.selected,
                        "--nav-item-active-color": theme.palette.text.primary,
                        "--nav-item-disabled-color": theme.palette.neutral[400],
                        "--nav-item-icon-color": theme.palette.neutral[400],
                        "--nav-item-icon-active-color": theme.palette.primary.main,
                        "--nav-item-icon-disabled-color": theme.palette.neutral[400],
                        "--nav-item-chevron-color": theme.palette.neutral[400],
                        "--nav-scrollbar-color": theme.palette.neutral[900],
                    };
                }

            case "evident":
                if (theme.palette.mode === "dark") {
                    return {
                        "--nav-bg": theme.palette.neutral[800],
                        "--nav-color": theme.palette.common.white,
                        "--nav-border-color": "transparent",
                        "--nav-logo-border": theme.palette.neutral[700],
                        "--nav-section-title-color": theme.palette.neutral[400],
                        "--nav-item-color": theme.palette.neutral[400],
                        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
                        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
                        "--nav-item-active-color": theme.palette.common.white,
                        "--nav-item-disabled-color": theme.palette.neutral[500],
                        "--nav-item-icon-color": theme.palette.neutral[400],
                        "--nav-item-icon-active-color": theme.palette.primary.main,
                        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
                        "--nav-item-chevron-color": theme.palette.neutral[600],
                        "--nav-scrollbar-color": theme.palette.neutral[400],
                    };
                } else {
                    return {
                        "--nav-bg": theme.palette.lux.main,
                        "--nav-color": theme.palette.lux.contrastText,
                        "--nav-border-color": "transparent",
                        "--nav-logo-border": theme.palette.neutral[700],
                        "--nav-section-title-color": theme.palette.neutral[400],
                        "--nav-item-color": theme.palette.lux.contrastText,
                        "--nav-item-hover-bg": theme.palette.lux.light,
                        "--nav-item-active-bg": theme.palette.lux.light,
                        "--nav-item-active-color": theme.palette.lux.contrastText,
                        "--nav-item-disabled-color": theme.palette.neutral[500],
                        "--nav-item-icon-color": theme.palette.lux.contrastText,
                        "--nav-item-icon-active-color": theme.palette.lux.contrastText,
                        "--nav-item-icon-disabled-color": theme.palette.neutral[500],
                        "--nav-item-chevron-color": theme.palette.neutral[600],
                        "--nav-scrollbar-color": theme.palette.neutral[400],
                    };
                }

            default:
                return {};
        }
    }, [theme, color]);
};

interface SideNavProps {
    color?: NavColor;
    sections?: Section[];
    desktopNavOpen: () => void;
}

export const SideNav: FC<SideNavProps> = (props) => {
    const { color = "evident", sections = [], desktopNavOpen } = props;
    const pathname = usePathname();
    const cssVars = useCssVars(color);

    return (
        <Box>
            <Stack
                sx={{ height: "100%" }}
                direction={"row"}
            >
                <Stack
                    component='nav'
                    spacing={2}
                    sx={{
                        flexGrow: 1,
                        px: 1,
                        pt: 4,
                        width: SIDE_NAV_WIDTH,
                        ...cssVars,
                        backgroundColor: "var(--nav-bg)",
                        borderRightColor: "var(--nav-border-color)",
                        borderRightStyle: "solid",
                        borderRightWidth: 1,
                        color: "var(--nav-color)",
                        top: "auto",
                        display: "flex",
                    }}
                >
                    {sections.map((section, index) => (
                        <SideNavSection
                            items={section.items}
                            key={"side-nav-section-" + index}
                            pathname={pathname}
                            subheader={section.subheader}
                            desktopNavOpen={desktopNavOpen}
                        />
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
};

SideNav.propTypes = {
    color: PropTypes.oneOf<NavColor>(["blend-in", "discrete", "evident"]),
    sections: PropTypes.array,
};
