import { FC, ReactNode, useState } from "react";
import { PopperProps, Popper, Fade, Box, styled, useTheme, SxProps, Theme } from "@mui/material";

export interface ActionPopperProps extends PopperProps {
    backgroundColor?: string;
}

export const ActionPopper: FC<ActionPopperProps> = ({
    children,
    backgroundColor,
    modifiers,
    ...restProps
}: ActionPopperProps) => {
    const theme = useTheme();
    const background = backgroundColor ? backgroundColor : theme.palette.background.popper;

    const styles = {
        arrow: {
            position: "absolute",
            fontSize: 7,
            width: "3em",
            height: "3em",
            "&::before": {
                content: '""',
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                borderStyle: "solid",
            },
        },
    };

    const [arrowRef, setArrowRef] = useState(null);

    return (
        <StyledPopper
            placement='top'
            disablePortal={false}
            transition
            {...restProps}
            sx={{ zIndex: 9999, backgroundColor: background, borderRadius: "8px", ...restProps.sx }}
            modifiers={[
                {
                    name: "flip",
                    enabled: true,
                    options: {
                        altBoundary: true,
                        rootBoundary: "document",
                        padding: 8,
                    },
                },
                {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                        altAxis: true,
                        altBoundary: true,
                        tether: true,
                        rootBoundary: "document",
                        padding: 8,
                    },
                },
                {
                    name: "arrow",
                    enabled: true,
                    options: {
                        element: arrowRef,
                    },
                },
                ...(modifiers ?? []),
            ]}
        >
            {({ TransitionProps }) => (
                <Fade
                    {...TransitionProps}
                    timeout={{ enter: 0, exit: 0 }}
                >
                    <Box
                        sx={{
                            bgcolor: background,
                            color: "text.popper",
                            borderRadius: "8px",
                        }}
                    >
                        {children as ReactNode}
                        <Box
                            component='span'
                            className='arrow'
                            ref={setArrowRef}
                            sx={styles.arrow}
                        />
                    </Box>
                </Fade>
            )}
            {/* {children} */}
        </StyledPopper>
    );
};

const StyledPopper = styled(Popper)(({ theme, sx }) => {
    const { backgroundColor } =
        (sx as { backgroundColor: string }) ?? ({ backgroundColor: theme.palette.background.popper } as SxProps<Theme>);
    return {
        zIndex: 1,
        maxWidth: "375px",
        minWidth: "150px",
        '&[data-popper-placement*="bottom"] .arrow': {
            top: 0,
            left: 0,
            marginTop: "-0.9em",
            width: "3em",
            height: "1em",
            "&::before": {
                borderWidth: "0 2em 2em 2em",
                borderColor: `transparent transparent ${backgroundColor} transparent`,
            },
        },
        '&[data-popper-placement*="top"] .arrow': {
            bottom: 0,
            left: 0,
            marginBottom: "-0.9em",
            width: "3em",
            height: "1em",
            "&::before": {
                borderWidth: "2em 2em 0 2em",
                borderColor: `${backgroundColor} transparent transparent transparent`,
            },
        },
        '&[data-popper-placement*="right"] .arrow': {
            left: 0,
            marginLeft: "-1.2em",
            height: "3em",
            width: "1em",
            "&::before": {
                borderWidth: "2em 2em 2em 0",
                borderColor: `transparent ${backgroundColor} transparent transparent`,
            },
        },
        '&[data-popper-placement*="left"] .arrow': {
            right: 0,
            marginRight: "-0.9em",
            height: "3em",
            width: "1em",
            "&::before": {
                borderWidth: "2em 0 2em 2em",
                borderColor: `transparent transparent transparent ${backgroundColor}`,
            },
        },
    };
});
