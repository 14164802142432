import { ReactNode, useMemo, FC } from "react";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/system/Stack";
import SubSideNavItem from "./sub-side-nav-item";
import { usePathname } from "src/hooks/use-pathname";
import type { NavColor } from "src/types/settings";

interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    label?: ReactNode;
    path?: string;
    title: string;
    styling?: {
        box: React.CSSProperties;
        label: React.CSSProperties;
        icon: React.CSSProperties;
    };
    active?: boolean;
}

type SubSideNavProps = {
    items?: Item[];
    open: boolean;
};

const getActiveSubItems = (items: Item[], pathname: string): Item[] => {
    return items.map((item) => ({
        ...item,
        active: item.path === pathname || pathname.includes(item.path || ""),
    }));
};

const useCssVars = (color: NavColor): Record<string, string> => {
    const theme = useTheme();
    return useMemo((): Record<string, string> => {
        return {
            "--nav-bg": "transparent",
            "--nav-color": theme.palette.lux.contrastText,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.lux.contrastText,
            "--nav-item-hover-bg": theme.palette.grey[200],
            "--nav-item-active-bg": theme.palette.lux.light,
            "--nav-item-active-color": theme.palette.lux.contrastText,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.lux.contrastText,
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
        };
    }, [theme, color]);
};

const SubSideNavMenu: FC<SubSideNavProps> = ({ items, open }) => {
    const pathname = usePathname();
    const cssVars = useCssVars("evident");

    const subItems = useMemo(() => items && getActiveSubItems(items, pathname), [items, pathname]);

    const flexProperties = {
        width: open ? "100%" : "0px",
        transition: "width 100ms linear",
        visibility: open ? "visible" : "hidden",
    };

    return (
        <Stack
            direction='column'
            spacing={0.5}
            sx={{ ...cssVars, color: "var(--nav-color)", ...flexProperties, p: 2, minWidth: 240 }}
        >
            {subItems &&
                getActiveSubItems(subItems, pathname).map((item, index) => (
                    <SubSideNavItem
                        key={`${item.title.replace(" ", "-")}-${index}`}
                        {...item}
                    />
                ))}
        </Stack>
    );
};

export default SubSideNavMenu;
