import React from "react";
import LuxSquareLogo from "/assets/SquareLogoWhiteX.png";
import LuxSmallLogo from "/assets/SmallLogoWhiteX.png";

interface LuxLogoProps {
    square?: boolean;
}

const LuxLogo: React.FC<LuxLogoProps> = ({ square }: LuxLogoProps) => {
    if (square) {
        return (
            <img
                src={LuxSquareLogo}
                alt='LUX Logo'
                width='60px'
                height='60px'
            />
        );
    }
    return (
        <img
            src={LuxSmallLogo}
            alt='LUX Logo'
            width='165px'
            height='35px'
        />
    );
};

export default LuxLogo;
