const {
    VITE_APP_API_BASE_URL,
    VITE_PREVIEW_TEMPLATE_PAGE_BASE_URL,
    VITE_HOTJAR_ID,
    VITE_HOTJAR_VERSION,
    VITE_DATADOG_APPLICATION_ID,
    VITE_DATADOG_CLIENT_TOKEN,
} = import.meta.env;

export {
    VITE_APP_API_BASE_URL,
    VITE_PREVIEW_TEMPLATE_PAGE_BASE_URL,
    VITE_HOTJAR_ID,
    VITE_HOTJAR_VERSION,
    VITE_DATADOG_APPLICATION_ID,
    VITE_DATADOG_CLIENT_TOKEN,
};
