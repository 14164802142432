import { hotjar } from "react-hotjar";
import { datadogRum } from "@datadog/browser-rum";
import * as appConstants from "src/constants";

export const initHotjar = () => {
    const hjid = appConstants.VITE_HOTJAR_ID;
    const hjsv = appConstants.VITE_HOTJAR_VERSION;
    if (hjid && hjsv) {
        hotjar.initialize(hjid, hjsv);
    }
};

export const initDatadog = () => {
    const ddApplicationId = appConstants.VITE_DATADOG_APPLICATION_ID;
    const ddClientToken = appConstants.VITE_DATADOG_CLIENT_TOKEN;
    if (ddApplicationId && ddClientToken) {
        datadogRum.init({
            applicationId: ddApplicationId,
            clientToken: ddClientToken,
            site: "datadoghq.com",
            service: "smart-page-builder---simba",
            env: "production",
            version: "1.0.0",
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
        });
    }
};
