import type { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        subtitle3: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        subtitle3?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        subtitle3: true;
    }
}

export const createTypography = (): TypographyOptions => {
    return {
        fontFamily: [
            "-apple-system",
            '"Montserrat"',
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: 1.57,
        },
        button: {
            fontWeight: 600,
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 500,
            lineHeight: 1.66,
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: 1.57,
        },
        subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: 1.57,
        },
        subtitle3: {
            fontSize: "0.65rem",
            fontWeight: 500,
            lineHeight: 1.5,
        },
        overline: {
            fontSize: "0.75rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: 2.5,
            textTransform: "uppercase",
        },
        h1: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 400,
            fontSize: "3.5rem",
            lineHeight: 1.2,
        },
        h2: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 400,
            fontSize: "3rem",
            lineHeight: 1.2,
        },
        h3: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 400,
            fontSize: "2.25rem",
            lineHeight: 1.2,
        },
        h4: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            fontSize: "2rem",
            lineHeight: 1.2,
        },
        h5: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 400,
            fontSize: "1.5rem",
            lineHeight: 1.2,
        },
        h6: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: 1.2,
        },
    };
};
