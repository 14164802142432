import React from "react";
import { Tooltip as MUITooltip, TooltipProps } from "@mui/material";

// Creating this wrapper tooltip component for the consistency and will replace all tooltips with this component
const Tooltip = ({ children, ...restProps }: TooltipProps) => {
    return (
        <MUITooltip
            arrow
            placement='top'
            {...restProps}
        >
            {children}
        </MUITooltip>
    );
};

export default Tooltip;
