import { create } from "../";
import { produce } from "immer";
import { AppliedFilter, SmartPage, SmartPageFilterQuery } from "src/types/smart-pages";
import { FieldUpdatedValue } from "src/sections/smart-pages/actions/fields/edit-fields";

type SmartPagesState = {
    rows: SmartPage[];
    selectedPageIds: readonly number[];
    selectedPages: readonly SmartPage[];
    totalSelectedPages: number;
    selectAllPagesSelected: boolean;
    showSelectionPage: boolean;
    showUpdatingPage: boolean;
    rowsAcrossAllPagesSelected: boolean;
    updatedFields: FieldUpdatedValue[];
    filterObject: SmartPageFilterQuery;
    showFilters: boolean;
    appliedFilters: AppliedFilter[];
    retainSelection: boolean;
    bulkUploadPagesCount: number | null;
    setRows: (data: SmartPage[]) => void;
    setSelectedPageIds: (data: readonly number[]) => void;
    setTotalSelectedPages: (data: number) => void;
    setShowSelectionPage: (data: boolean) => void;
    setShowUpdatingPage: (data: boolean) => void;
    setSelectAllPagesSelected: (data: boolean) => void;
    setRowsAcrossAllPagesSelected: (data: boolean) => void;
    setUpdatedFields: (data: FieldUpdatedValue[]) => void;
    setFilterObject: (data: SmartPageFilterQuery) => void;
    setResetAll: () => void;
    setShowFilters: (data: boolean) => void;
    setAppliedFilters: (data: AppliedFilter[]) => void;
    changeSelectedPages: () => void;
    setBulkUploadPagesCount: (data: number | null) => void;
};

export const useSmartPagesStore = create<SmartPagesState>()((set) => {
    const initialState = {
        rows: [],
        selectedPageIds: [],
        selectedPages: [],
        totalSelectedPages: 0,
        selectAllPagesSelected: false,
        showSelectionPage: false,
        showUpdatingPage: false,
        rowsAcrossAllPagesSelected: false,
        updatedFields: [],
        filterObject: {},
        showFilters: false,
        appliedFilters: [],
        retainSelection: false,
        bulkUploadPagesCount: null,
    };

    return {
        ...initialState,
        setRows: (data) =>
            set(
                produce((draft) => {
                    draft.rows = data;
                    // Reset the selected pages when the rows change
                    if (!draft.retainSelection) {
                        draft.selectedPageIds = [];
                        draft.selectedPages = [];
                        draft.totalSelectedPages = 0;
                        draft.selectAllPagesSelected = false;
                        draft.rowsAcrossAllPagesSelected = false;
                    }
                    draft.retainSelection = false;
                })
            ),
        setShowSelectionPage: (data) =>
            set(
                produce((draft) => {
                    draft.showSelectionPage = data;
                })
            ),
        setShowUpdatingPage: (data) =>
            set(
                produce((draft) => {
                    draft.showUpdatingPage = data;
                })
            ),
        setSelectedPageIds: (data) =>
            set(
                produce((draft) => {
                    draft.selectedPageIds = data;
                    draft.selectedPages = draft.rows.filter((row: SmartPage) => draft.selectedPageIds.includes(row.id));
                    draft.totalSelectedPages = draft.selectedPages.length;
                })
            ),
        setSelectAllPagesSelected: (data) =>
            set(
                produce((draft) => {
                    draft.selectAllPagesSelected = data;
                })
            ),
        setTotalSelectedPages: (data) =>
            set(
                produce((draft) => {
                    draft.totalSelectedPages = data;
                })
            ),
        setRowsAcrossAllPagesSelected: (data) =>
            set(
                produce((draft) => {
                    draft.rowsAcrossAllPagesSelected = data;
                })
            ),
        setUpdatedFields: (data) =>
            set(
                produce((draft) => {
                    draft.updatedFields = data;
                })
            ),
        setFilterObject: (data) =>
            set(
                produce((draft) => {
                    draft.filterObject = data;
                })
            ),
        setShowFilters: (data) =>
            set(
                produce((draft) => {
                    draft.showFilters = data;
                })
            ),
        setAppliedFilters: (data) =>
            set(
                produce((draft) => {
                    draft.appliedFilters = data;
                })
            ),
        setResetAll: () => set(initialState),
        changeSelectedPages: () =>
            set(
                produce((draft) => {
                    draft.showSelectionPage = false;
                    draft.retainSelection = true;
                })
            ),
        setBulkUploadPagesCount: (data) =>
            set(
                produce((draft) => {
                    draft.bulkUploadPagesCount = data;
                })
            ),
    };
});
