import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { Theme } from "@mui/material/styles/createTheme";
import { styled } from "@mui/material/styles";
import type { NavColor } from "src/types/settings";
import type { Section } from "../config";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import { useDesktopNav } from "./use-desktop-nav";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import WidgetDrawer from "src/components/drawer";

const VerticalLayoutRoot = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    position: "relative",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    transition: "all 2s",
    paddingBottom: theme.spacing(3),
    maxHeight: "calc(100vh - 64px)",
}));

interface VerticalLayoutProps {
    children?: ReactNode;
    navColor?: NavColor;
    sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
    const { children, sections, navColor } = props;
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
    const mobileNav = useMobileNav();
    const desktopNav = useDesktopNav();

    return (
        <>
            <TopNav onNavOpen={mobileNav.handleOpen} />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: "1 1 auto",
                    flexGrow: 1,
                    transition: "flex 1000ms linear",
                }}
            >
                {lgUp && (
                    <Stack direction={"row"}>
                        <SideNav
                            color={navColor}
                            sections={sections}
                            desktopNavOpen={desktopNav.handleOpen}
                        />
                    </Stack>
                )}
                {!lgUp && (
                    <MobileNav
                        color={navColor}
                        onClose={mobileNav.handleClose}
                        open={mobileNav.open}
                        sections={sections}
                    />
                )}
                <VerticalLayoutRoot
                    id='verticalLayout'
                    maxWidth={false}
                    sx={{ overflow: "auto" }}
                >
                    {children}
                    <WidgetDrawer />
                </VerticalLayoutRoot>
            </Box>
        </>
    );
};

VerticalLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf<NavColor>(["blend-in", "discrete", "evident"]),
    sections: PropTypes.array,
};
