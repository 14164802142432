import { createTheme as createMuiTheme } from "@mui/material";
import { createPalette } from "./create-palette";
import { createComponents } from "./create-components";
import { createShadows } from "./create-shadows";
import { createTypography } from "./create-typography";
import type { Theme } from "@mui/material/styles/createTheme";

declare module "@mui/material/styles/createPalette" {
    interface ColorRange {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    }

    interface Palette {
        neutral: ColorRange;
        lux: PaletteColor;
    }

    interface PaletteColor {
        lightest?: string;
        lighter?: string;
        darkest?: string;
        alpha4?: string;
        alpha8?: string;
        alpha12?: string;
        alpha30?: string;
        alpha50?: string;
    }

    interface PaletteOptions {
        neutral?: ColorRange;
        lux?: ColorRange;
    }

    interface TypeBackground {
        paper: string;
        popper: string;
        default: string;
    }

    interface TypeText {
        popper: string;
    }
}

export function createTheme(): Theme {
    const palette = createPalette();
    const components = createComponents({ palette });
    const shadows = createShadows();
    const typography = createTypography();

    return createMuiTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1440,
            },
        },
        components,
        palette,
        shadows,
        shape: {
            borderRadius: 8,
        },
        typography,
    });
}
