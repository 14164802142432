import React from "react";
import MUIBreadcrumbs, { BreadcrumbsProps as MUIBreadcrumbsProps } from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export interface BreadcrumbsProps extends MUIBreadcrumbsProps {
    // extending BreadcrumbsProps
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ children, ...rest }) => {
    return (
        <MUIBreadcrumbs
            aria-label='breadcrumb'
            separator={<NavigateNextIcon fontSize='small' />}
            {...rest}
        >
            {children}
        </MUIBreadcrumbs>
    );
};

export default Breadcrumbs;
