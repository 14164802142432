import { useCallback, FC, useState } from "react";
import { MenuItem, MenuList, Popover } from "@mui/material";
import { shallow } from "zustand/shallow";

import { useRouter } from "src/hooks/use-router";
import { useAuth } from "src/hooks/use-auth";
import { usePathname } from "src/hooks/use-pathname";
import { useDialog } from "src/hooks/use-dialog";
import { useCampaignFormStore } from "src/store/form";

import ConfirmationDialog from "src/components/confirmation-dialog";
import PropTypes from "prop-types";

interface TeamPopoverProps {
	anchorEl?: null | HTMLElement;
	onClose?: () => void;
	open?: boolean;
}

export const TeamPopover: FC<TeamPopoverProps> = (props) => {
	const { anchorEl, onClose, open } = props;
	const router = useRouter();
	const auth = useAuth();
	const [newClient, setNewClient] = useState<string>("");
	const pathName = usePathname();

	const clearForm = useCampaignFormStore((state) => state.clearForm);
	const formValues = useCampaignFormStore((state) => state.formData, shallow);

	const dialog = useDialog();

	const handleConfirm = () => {
		clearForm();
		dialog.handleConfirm();
		handleTeamChange(newClient);
	}

	const handleTeamSelect = (client: string) => {
		if (client === auth.selectedClient?.clientUsername) {
			return;
		}
		setNewClient(() => client);
		if (pathName === "/campaigns/create" && formValues.type !== null) {
			dialog.handleOpen();
		} else {
			handleTeamChange(client);
		}
	}

	const handleTeamChange = useCallback(
		async (clientUsername: string): Promise<void> => {
			try {
				const selectedClient = auth.clientList?.find(
					(client) => client.clientUsername == clientUsername
				) as any;
				auth.changeClient(selectedClient);
				onClose?.();

			} catch (err) {
				console.error(err);
			}
		},
		[auth, router, onClose]
	);

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: "left",
				vertical: "bottom",
			}}
			onClose={onClose}
			open={!!open}
			PaperProps={{ sx: { width: 200 } }}
		>
			<MenuList
				disablePadding
				dense
				sx={{
					p: "8px",
					"& > *": {
						borderRadius: 1,
					},
				}}
			>
				{auth.clientList && auth.clientList.length > 0 ? (
					auth.clientList
						.slice() // Create a copy of the array to avoid mutating the original
						.sort((a, b) => a.name.localeCompare(b.name)) // Sort by client name
						.map((client, index) => (
							<MenuItem
								key={index}
								onClick={() =>
									handleTeamSelect(client.clientUsername)
								}
							>
								{client.name}
							</MenuItem>
						))
				) : (
					<></>
				)}
			</MenuList>
			<ConfirmationDialog
				open={dialog.open}
				onClose={dialog.handleCancel}
				onConfirm={handleConfirm}
				title="Clear Form"
				content="Changing clients will clear the form, are you sure you want to continue?"
			/>
		</Popover>
	);
};

TeamPopover.propTypes = {
	anchorEl: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
};
