import { FC, lazy, ReactElement, Suspense } from "react";
import { Outlet, Navigate, useParams } from "react-router-dom";
import { type RouteObject } from "react-router";

import { usePageValidity } from "src/hooks/use-page-validity";

import { Layout as DashboardLayout } from "src/layouts/dashboard/index";
import { authRoutes } from "./auth";
import { useAuth } from "src/hooks/use-auth";

const CampaignHomePage = lazy(() => import("src/pages/index"));
const CreateCampaignPage = lazy(() => import("src/pages/campaign/campaign-create"));
const EditCampaignPage = lazy(() => import("src/pages/campaign/campaign-edit"));
const AllCampaigns = lazy(() => import("src/pages/campaign/all-campaigns"));
const CampaignPerformance = lazy(() => import("src/pages/campaign/campaign-performance"));
const TestPage = lazy(() => import("src/pages/campaign/test-page"));
const CampaignLaunchDetails = lazy(() => import("src/pages/campaign/campaign-launch-details"));

// Categories
const AllCategories = lazy(() => import("src/pages/categories/all-categories"));
const Customise = lazy(() => import("src/pages/categories/customise"));
const Widgets = lazy(() => import("src/pages/categories/widget-table"));

// Smart pages
const AllSmartPages = lazy(() => import("src/pages/smart-pages/all-smart-pages"));
const EditSmartPage = lazy(() => import("src/pages/smart-pages/edit-smart-page"));

interface ProtectedRouteProps {
    children: ReactElement;
    requireCampaignsValid: boolean;
    requireCategoriesValid: boolean;
}

// This component decides whether to redirect to the campaigns page or categories page based on the selected client
const ConditionalHomeRedirect = () => {
    const { team } = useParams();
    const { campaignsPageValid, organicPageValid } = usePageValidity();

    if (organicPageValid) {
        // Redirect to the specified path if campaigns are not valid
        return <Navigate to={`/${team}/pages`} />;
    }

    if (campaignsPageValid) {
        // Redirect to the specified path if campaigns are not valid
        return <Navigate to={`/${team}/campaigns`} />;
    }

    return <CampaignHomePage />;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, requireCampaignsValid, requireCategoriesValid }) => {
    const { team } = useParams();
    const { campaignsPageValid, organicPageValid } = usePageValidity();

    // Redirect logic based on page validity and required access
    if (requireCampaignsValid && !campaignsPageValid) {
        return (
            <Navigate
                to={`/${team}/pages`}
                replace
            />
        );
    } else if (requireCategoriesValid && !organicPageValid) {
        // Redirect to campaigns if user manually navigates to categories without access
        return (
            <Navigate
                to={`/${team}/campaigns`}
                replace
            />
        );
    }

    // Render children if access conditions are met
    return children;
};

// navigate to /:team when navigating to /
const SetTeamRoute: FC = () => {
    const { selectedClient } = useAuth();
    if (selectedClient && selectedClient.clientUsername) {
        return <Navigate to={`/${selectedClient.clientUsername}`} />;
    }

    return <CampaignHomePage />;
};

export const routes: RouteObject[] = [
    {
        path: "/",
        element: (
            <DashboardLayout>
                <Suspense>
                    <Outlet />
                </Suspense>
            </DashboardLayout>
        ),
        children: [
            {
                index: true,
                element: <SetTeamRoute />,
            },
            {
                path: ":team",
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <ConditionalHomeRedirect />,
                    },
                    {
                        path: "campaigns",
                        element: (
                            <ProtectedRoute
                                requireCategoriesValid={false}
                                requireCampaignsValid={true}
                            >
                                <Outlet />
                            </ProtectedRoute>
                        ),
                        children: [
                            {
                                index: true,
                                element: (
                                    <Navigate
                                        to='all-campaigns'
                                        replace
                                    />
                                ),
                            },
                            {
                                path: "create",
                                element: <CreateCampaignPage />,
                            },
                            {
                                path: "edit/:id",
                                element: <EditCampaignPage />,
                            },
                            {
                                path: "all-campaigns",
                                element: <AllCampaigns />,
                            },
                            {
                                path: "performance",
                                element: <CampaignPerformance />,
                            },
                            {
                                path: "launch-details/:id",
                                element: <CampaignLaunchDetails />,
                            },
                        ],
                    },
                    {
                        path: "categories",
                        element: (
                            <ProtectedRoute
                                requireCategoriesValid={true}
                                requireCampaignsValid={false}
                            >
                                <Outlet />
                            </ProtectedRoute>
                        ),
                        children: [
                            {
                                index: true,
                                element: (
                                    <Navigate
                                        to='all-categories'
                                        replace
                                    />
                                ),
                            },
                            {
                                path: "all-categories",
                                element: <AllCategories />,
                            },
                            {
                                path: "customise",
                                element: <Customise />,
                            },
                            {
                                path: "customise/:id",
                                element: <Customise />,
                            },
                            {
                                path: "all-widgets",
                                element: <Widgets />,
                            },
                        ],
                    },
                    {
                        path: "pages",
                        element: (
                            <ProtectedRoute
                                requireCategoriesValid={true}
                                requireCampaignsValid={false}
                            >
                                <Outlet />
                            </ProtectedRoute>
                        ),
                        children: [
                            {
                                index: true,
                                element: (
                                    <Navigate
                                        to='all-pages'
                                        replace
                                    />
                                ),
                            },
                            {
                                path: "edit/:id",
                                element: <EditSmartPage />,
                            },
                            {
                                path: "all-pages",
                                element: <AllSmartPages />,
                            },
                        ],
                    },
                    {
                        path: "lux-test-page",
                        element: <TestPage />,
                    },
                ],
            },
            {
                path: "*",
                element: (
                    <Navigate
                        to='/'
                        replace
                    />
                ),
            },
        ],
    },
    ...authRoutes,
];
