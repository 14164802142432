export const paths = {
    index: "/",
    auth: {
        forgotPassword: "/auth/forgot-password",
        login: "/auth/login",
        newPassword: "/auth/password/reset",
    },
    campaign: {
        index: "/campaigns",
        create: "/campaigns/create",
        edit: "/campaigns/edit/",
        allCampaigns: "/campaigns/all-campaigns",
        performance: "/campaigns/performance",
        testPage: "/campaigns/test-page",
        launchDetails: "/campaigns/launch-details/",
    },
    categories: {
        index: "/categories",
        allCategories: "/categories/all-categories",
        customise: "/categories/customise",
        allWidgets: "/categories/all-widgets",
    },
    smartPages: {
        index: "/pages",
        allSmartPages: "/pages/all-pages",
        edit: "/pages/edit",
    },
    notAuthorized: "/401",
    notFound: "/404",
    serverError: "/500",
};
