import React from "react";

import { useDialog } from "src/hooks/use-dialog";

import ConfirmationDialog from "src/components/confirmation-dialog";
import { useSmartPagesStore } from "src/store/smart-pages";

interface BulkActionsCancelDialogProps {
    dialogRef: any;
}

export const BulkActionsCancelDialog: React.FC<BulkActionsCancelDialogProps> = ({ dialogRef }) => {
    const dialog = dialogRef;
    const { setResetAll } = useSmartPagesStore();

    return (
        <ConfirmationDialog
            open={dialog.open}
            onClose={dialog.handleCancel}
            onConfirm={() => {
                setResetAll();
                dialog.handleConfirm();
            }}
            title='Discard Changes and Exit?'
            content='Leaving this page will discard any unsaved changes to the status change'
            confirmButtonText='Exit'
        />
    );
};
