import { type ReactNode } from "react";
import { useParams } from "react-router-dom";

import SvgIcon from "@mui/material/SvgIcon";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import CategoryIcon from "/assets/category.svg";
import WebStoriesOutlinedIcon from "@mui/icons-material/WebStoriesOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";

import { usePageValidity } from "src/hooks/use-page-validity";
// import { useAuth } from "src/hooks/use-auth";
import { useSmartPagesStore } from "src/store/smart-pages";
import { paths } from "src/paths";
import { useDialog } from "src/hooks/use-dialog";
import { BulkActionsCancelDialog } from "src/sections/smart-pages/actions/bulk-actions-cancel-dialog";
import { Theme, useTheme } from "@mui/material";

export interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    title: string;
}

export interface Section {
    items: Item[];
    subheader?: string;
    hide?: boolean;
}

const actionButtonStyling = (theme: Theme) => ({
    box: {
        height: "56px",
        backgroundColor: "#DDE1FF",
        color: theme.palette.primary.main,
        borderRadius: "16px",
        "&:hover": {
            boxShadow: "1",
        },
        // width: "180px",
        width: "fit-content",
        mb: 1,
        paddingRight: "20px",
    },
    label: {
        color: theme.palette.primary.main,
    },
    icon: {
        color: theme.palette.primary.main,
    },
});

export const useSections = () => {
    const theme = useTheme();
    const { campaignsPageValid, organicPageValid } = usePageValidity();
    const { showSelectionPage, showUpdatingPage, setResetAll } = useSmartPagesStore();
    const { team } = useParams();

    // const { user } = useAuth();
    // const isLuxAdmin = user?.isLuxAdmin;
    // const [confirmationOpen, setConfirmationOpen] = useState(false);

    const dialog = useDialog();

    const campaignItems = {
        title: "Campaigns",
        path: team + paths.campaign.allCampaigns,
        disabledMessage:
            "Looks like you’ve discovered a new module that’s not part of your subscribed plan. Drop us an email at product@longtailux.com to learn more.",
        icon: (
            <SvgIcon fontSize='medium'>
                <CampaignOutlinedIcon />
            </SvgIcon>
        ),
        items: [
            {
                title: "Add Campaign",
                path: team + paths.campaign.create,
                icon: (
                    <SvgIcon fontSize='small'>
                        <AddIcon />
                    </SvgIcon>
                ),
                styling: actionButtonStyling(theme),
            },
            {
                title: "All Campaigns",
                path: team + paths.campaign.allCampaigns,
                icon: (
                    <SvgIcon fontSize='small'>
                        <UpdateIcon />
                    </SvgIcon>
                ),
            },
        ],
    };

    const categoryItems = {
        title: "Categories",
        path: team + paths.categories.allCategories,
        icon: (
            <img
                src={CategoryIcon}
                alt='Categories'
            />
        ),
        items: [
            {
                title: "Customise",
                path: team + paths.categories.customise,
                icon: (
                    <SvgIcon fontSize='medium'>
                        <EditIcon />
                    </SvgIcon>
                ),
                styling: actionButtonStyling(theme),
            },
            {
                title: "All Categories",
                path: team + paths.categories.allCategories,
                icon: (
                    <img
                        src={CategoryIcon}
                        alt='Categories'
                    />
                ),
            },
            {
                title: "All Widgets",
                path: team + paths.categories.allWidgets,
                icon: (
                    <SvgIcon fontSize='medium'>
                        <WidgetsOutlinedIcon />
                    </SvgIcon>
                ),
            },
        ],
    };

    const onSmartPagesLinkClick = () => {
        if (showSelectionPage) {
            dialog.handleOpen();
        }
        if (showUpdatingPage) {
            setResetAll();
        }
    };

    const smartPagesItems = {
        title: "Pages",
        path: team + paths.smartPages.allSmartPages,
        icon: (
            <SvgIcon fontSize='medium'>
                <WebStoriesOutlinedIcon />
            </SvgIcon>
        ),
        items: [
            {
                title: "Smart Pages",
                path: team + paths.smartPages.allSmartPages,
                icon: (
                    <SvgIcon fontSize='medium'>
                        <WebStoriesOutlinedIcon />
                    </SvgIcon>
                ),
                onItemClick: onSmartPagesLinkClick,
                confirmationDialogComponent: <BulkActionsCancelDialog dialogRef={dialog} />,
            },
        ],
    };

    const items = [];

    items.push({
        items: [
            { ...campaignItems, disabled: !campaignsPageValid },
            { ...categoryItems, hide: !organicPageValid },
            { ...smartPagesItems, hide: !organicPageValid },
        ],
    });

    return items;
};
