import { create } from "../";
import { produce } from "immer";
import { ReactNode } from "react";

type WidgetState = {
    showDrawer: boolean;
    showModal: boolean;
    drawerTitle: ReactNode;
    drawerContent: ReactNode;
    setDrawer: (data: boolean) => void;
    setDrawerTitle: (data: string) => void;
    setDrawerContent: (data: ReactNode) => void;
    setModal: (data: boolean) => void;
};

export const useUiStore = create<WidgetState>()((set) => {
    const initialState = {
        showDrawer: false,
        showModal: false,
        category: {},
        widgetType: "meta-tags",
        drawerContent: null,
        drawerTitle: "Add Widget",
    };

    return {
        ...initialState,
        setDrawer: (data) =>
            set(
                produce((draft) => {
                    draft.showDrawer = data;
                })
            ),
        setModal: (data) =>
            set(
                produce((draft) => {
                    draft.showModal = data;
                })
            ),
        setDrawerTitle: (data) =>
            set(
                produce((draft) => {
                    draft.drawerTitle = data;
                })
            ),
        setDrawerContent: (data) =>
            set(
                produce((draft) => {
                    draft.drawerContent = data;
                })
            ),
    };
});
