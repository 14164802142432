import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import { useRouter } from "src/hooks/use-router";

import { authApi } from "src/api/auth/index";
import { AuthContext, initialState } from "./auth-context";
import type { State } from "./auth-context";
import type { User, UserClient } from "src/types/user";
import { ACCESS_TOKEN_KEY, SELECTED_CLIENT_KEY } from "../index";
import { resetAllStores } from "src/store";

enum ActionType {
    INITIALIZE = "INITIALIZE",
    SIGN_IN = "SIGN_IN",
    SIGN_OUT = "SIGN_OUT",
    CHANGE_CLIENT = "CHANGE_CLIENT",
}

type InitializeAction = {
    type: ActionType.INITIALIZE;
    payload: {
        isAuthenticated: boolean;
        user: User | null;
        clientList: UserClient[] | null;
        selectedClient: UserClient | null;
    };
};

type SignInAction = {
    type: ActionType.SIGN_IN;
    payload: {
        user: User;
        clientList: UserClient[] | null;
        selectedClient: UserClient | null;
    };
};

type SignOutAction = {
    type: ActionType.SIGN_OUT;
};

type ChangeClientAction = {
    type: ActionType.CHANGE_CLIENT;
    payload: {
        selectedClient: UserClient | null;
    };
};

type Action = InitializeAction | SignInAction | SignOutAction | ChangeClientAction;

type Handler = (state: State, action: any) => State;

type BroadcastEvent = MessageEvent & {
    data: "login" | "logout";
};

const handlers: Record<ActionType, Handler> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user, clientList, selectedClient } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            clientList,
            selectedClient,
        };
    },
    SIGN_IN: (state: State, action: SignInAction): State => {
        const { user, clientList, selectedClient } = action.payload;
        return {
            ...state,
            isAuthenticated: true,
            user,
            clientList,
            selectedClient,
        };
    },
    SIGN_OUT: (state: State): State => {
        return {
            ...state,
            isAuthenticated: false,
            isInitialized: false,
            user: null,
            clientList: [],
            selectedClient: null,
        };
    },
    CHANGE_CLIENT: (state: State, action: ChangeClientAction): State => {
        const { selectedClient } = action.payload;
        return {
            ...state,
            selectedClient,
        };
    },
};

const reducer = (state: State, action: Action): State =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const router = useRouter();

    const broadcastChannel = new BroadcastChannel("auth");

    const initialize = useCallback(async (): Promise<void> => {
        try {
            const { accessToken } = await authApi.refreshToken();
            if (accessToken) {
                const user = await authApi.me();
                const clientList = await authApi.getClientList();
                const selectedClient = getSelectedClient(clientList);
                router.updateTeamRoute("team", selectedClient?.clientUsername ?? "");
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: true,
                        user,
                        clientList,
                        selectedClient,
                    },
                });
            } else {
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        clientList: null,
                        selectedClient: null,
                    },
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: ActionType.INITIALIZE,
                payload: {
                    isAuthenticated: false,
                    user: null,
                    clientList: null,
                    selectedClient: null,
                },
            });
        }
    }, [dispatch]);

    useEffect(() => {
        initialize();

        const handleBroadcastMessage = (event: BroadcastEvent) => {
            if (event.data === "login" || event.data === "logout") {
                router.refresh();
            }
        };

        broadcastChannel.addEventListener("message", handleBroadcastMessage);

        return () => {
            broadcastChannel.removeEventListener("message", handleBroadcastMessage);
            broadcastChannel.close();
        };
    }, []);

    const signIn = useCallback(
        async (email: string, password: string): Promise<void> => {
            const { accessToken } = await authApi.signIn({
                email,
                password,
            });
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

            const user = await authApi.me();
            const clientList = await authApi.getClientList();
            const selectedClient = getSelectedClient(clientList);
            broadcastChannel.postMessage("login");
            dispatch({
                type: ActionType.SIGN_IN,
                payload: {
                    user,
                    clientList,
                    selectedClient,
                },
            });
        },
        [dispatch]
    );

    const signOut = useCallback(async (): Promise<void> => {
        try {
            await authApi.signOut();
        } catch (err) {
            console.error(err);
        }
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        dispatch({ type: ActionType.SIGN_OUT });
        broadcastChannel.postMessage("logout");
    }, [dispatch]);

    const changeClient = useCallback(
        async (selectedClient: UserClient): Promise<void> => {
            sessionStorage.setItem(SELECTED_CLIENT_KEY, JSON.stringify(selectedClient));
            resetAllStores();
            router.updateTeamRoute("team", selectedClient.clientUsername);
            dispatch({
                type: ActionType.CHANGE_CLIENT,
                payload: {
                    selectedClient,
                },
            });
        },
        [dispatch, router]
    );

    // set selected client in order of:
    // 1. clientusername in url
    // 2. clientusername doesn't exist in url and selectedClient object exists in session storage
    // 3. if no client username in url and no selectedClient object in session storage,
    // set the first client in alphabetical order
    const getSelectedClient = (clientList: UserClient[]) => {
        const team = window.location.pathname.split("/")[1];
        const selectedClientJSON = sessionStorage.getItem(SELECTED_CLIENT_KEY);
        const storedClient: UserClient = selectedClientJSON ? JSON.parse(selectedClientJSON) : {};

        if (team) {
            const validClient = clientList.find((client) => client.clientUsername === team);

            if (validClient) {
                sessionStorage.setItem(SELECTED_CLIENT_KEY, JSON.stringify(validClient));
                return validClient;
            }
        }

        if (storedClient.clientUsername) {
            return storedClient;
        }

        const firstAlphabeticalClient = clientList.reduce(
            (firstClient, currentClient) =>
                !firstClient || currentClient.clientUsername < firstClient.clientUsername ? currentClient : firstClient,
            null as UserClient | null
        );

        if (firstAlphabeticalClient) {
            sessionStorage.setItem(SELECTED_CLIENT_KEY, JSON.stringify(firstAlphabeticalClient));
            return firstAlphabeticalClient;
        }

        console.error("Error setting the selected client id");
        return null; // Or handle the error according to your needs
    };

    const forgotPassword = useCallback(
        async (email: string): Promise<void> => {
            await authApi.forgotPassword({ email });
        },
        [dispatch]
    );

    const resetPassword = useCallback(
        async (email: string, password: string, confirmPassword: string, token: string): Promise<void> => {
            await authApi.resetPassword({
                email,
                password,
                confirmPassword,
                token,
            });
        },
        [dispatch]
    );

    let tokenRefreshIntervalId: NodeJS.Timeout | null = null;
    const tokenRefreshInterval: number = 15; // refresh token every 15 minutes

    const startTokenRefreshInterval = useCallback(async (): Promise<void> => {
        clearTokenRefreshInterval();
        tokenRefreshIntervalId = setInterval(async () => {
            try {
                await authApi.refreshToken();
            } catch (error) {
                console.error("Failed to refresh token:", error);
                signOut();
            }
        }, tokenRefreshInterval * 60 * 1000);
    }, [dispatch]);

    const clearTokenRefreshInterval = useCallback(async (): Promise<void> => {
        if (tokenRefreshIntervalId !== null) {
            clearInterval(tokenRefreshIntervalId);
            tokenRefreshIntervalId = null;
        }
    }, [dispatch]);

    return (
        <AuthContext.Provider
            value={{
                ...state,
                issuer: "JWT",
                signIn,
                signOut,
                changeClient,
                forgotPassword,
                resetPassword,
                startTokenRefreshInterval,
                clearTokenRefreshInterval,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
