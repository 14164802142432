import axios from "axios";
import { VITE_APP_API_BASE_URL } from "src/constants";
import { ACCESS_TOKEN_KEY } from "src/contexts";

axios.defaults.headers.common["Content-Type"] = "application/json";

const client = axios.create({
    baseURL: VITE_APP_API_BASE_URL,
});

client.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

client.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401 && !error?.config?.url?.includes("/auth/login")) {
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default client;
