import { create as _create } from "zustand";
import type { StateCreator } from "zustand";

const storeResetFns = new Set<() => void>();

export const resetAllStores = () => {
    storeResetFns.forEach((resetFn) => {
        resetFn(); // Calls each store's reset function
    });
};

export const create = (<T extends unknown>() => {
    return (stateCreator: StateCreator<T>) => {
        const store = _create(stateCreator); // Create the store using the original 'create'
        const initialState = store.getState(); // Capture the initial state right after store creation

        // Register a reset function in the set
        storeResetFns.add(() => {
            store.setState(initialState, true); // Reset the store state to its initial state
        });

        return store; // Return the created store
    };
}) as typeof _create;
