import { type FC, type ReactNode } from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import { RouterLink } from "src/components/router-link";
interface SideNavItemProps {
    active?: boolean;
    children?: ReactNode;
    depth?: number;
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    label?: ReactNode;
    open?: boolean;
    path?: string;
    title: string;
    styling?: {
        box: React.CSSProperties;
        label: React.CSSProperties;
        icon: React.CSSProperties;
    };
}
const SubSideNavItem: FC<SideNavItemProps> = (props) => {
    const { active, icon, label, path, title, styling, component, onItemClick, confirmationDialogComponent } = props;

    let startIcon: ReactNode;

    startIcon = icon;

    const offset = 0;

    let linkProps = {
        component: RouterLink,
        href: path,
    };

    if (onItemClick) {
        linkProps = {
            onClick: onItemClick,
        };
    }

    if (component) {
        return <li>{component}</li>;
    }

    return (
        <li style={{ listStyle: "none" }}>
            <ButtonBase
                id='buttonbase'
                sx={{
                    alignItems: "center",
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "flex-start",
                    pl: `${16 + offset}px`,
                    pr: "16px",
                    py: "12px",
                    textAlign: "left",
                    backgroundColor: "var(--nav-bg)",
                    width: "100%",
                    ...(active && {
                        backgroundColor: "var(--nav-item-active-bg)",
                    }),
                    "&:hover": {
                        backgroundColor: "var(--nav-item-hover-bg)",
                    },
                    ...styling?.box,
                }}
                {...linkProps}
            >
                {startIcon && (
                    <Box
                        component='span'
                        sx={{
                            alignItems: "center",
                            color: "var(--nav-item-icon-color)",
                            display: "inline-flex",
                            justifyContent: "center",
                            mr: 1.5,
                            ...styling?.icon,
                        }}
                    >
                        {startIcon}
                    </Box>
                )}
                <Box
                    component='span'
                    sx={{
                        color: "var(--nav-item-color)",
                        flexGrow: 1,
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "24px",
                        whiteSpace: "nowrap",
                        ...(active && {
                            color: "var(--nav-item-active-color)",
                        }),
                        ...styling?.label,
                    }}
                >
                    {title}
                </Box>
                {label && (
                    <Box
                        component='span'
                        sx={{ ml: 2 }}
                    >
                        {label}
                    </Box>
                )}
            </ButtonBase>
            {confirmationDialogComponent}
        </li>
    );
};

export default SubSideNavItem;
