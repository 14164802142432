
import { CampaignFormValuesType, CampaignTypes } from 'src/types/campaign';

export const getSteps = (pageType: CampaignTypes, selectedCategory: string = '', campaignName: string = '') => {

  const getProductsSubtitle = () => {
    return `Add and rank '${selectedCategory}' category products to your '${campaignName}' campaign.`;
  }

  const getAttributesRulesSubtitle = () => {
    return `Set and rank '${selectedCategory}' category attributes to your '${campaignName}' campaign.`;
  }

  const getPricingRulesSubtitle = () => {
    return `Add and rank pricing rules to your '${campaignName}' campaign.`;
  }

  switch (pageType) {
    case CampaignTypes.Static:
      return [
        // { step: 'Type', title: "Create Your Campaign Page", subTitle: "Choose a campaign type" },
        { step: 'Details', title: "Create Your Campaign Page", subTitle: "Enter your campaign details." },
        { step: 'Products', title: "Create Your Campaign Page", subTitle: getProductsSubtitle() },
        { step: 'Rules', title: "Create Your Campaign Page", subTitle: getPricingRulesSubtitle() },
        // { step: 'Pricing', title: "Create Your Campaign Page", subTitle: "Enter your campaign tags and stopwords" },
        { step: 'Preview', title: "Create Your Campaign Page", subTitle: "Confirm campaign details." }
      ];
    case CampaignTypes.Dynamic:
      return [
        { step: 'Details', title: "Create Your Campaign Page", subTitle: "Enter your campaign details." },
        { step: 'Attributes', title: "Create Your Campaign Page", subTitle: getAttributesRulesSubtitle() },
        { step: 'Products', title: "Create Your Campaign Page", subTitle: getProductsSubtitle() },
        { step: 'Rules', title: "Create Your Campaign Page", subTitle: getPricingRulesSubtitle() },
        { step: 'Preview', title: "Create Your Campaign Page", subTitle: "Confirm campaign details." }
      ];
    default:
      return [];
  }
}

export const FORM_STATE: CampaignFormValuesType = {
  selected_index: 0,
  steps_completed: [],
  id: null,
  type: null,
  steps: {
    details: {
      value: {
        name: "",
        campaignName: "",
        pageTitle: "",
        url: "",
        start_date: null,
        end_date: null,
        on_going: true,
        tags: [],
        category_id: null,
        category_name: "",
        duplicate_url: false,
      },
      conditions: {
        url_manual_entry: false,
      }
    },
    attributes: {
      value: {
        queryValue: null,
        queryResult: null,
        queryLogic: null
      },
    },
    pricing_rules: {
      value: {
        queryValue: null,
        queryResult: null,
        queryLogic: null,
        out_of_stock: true,
      },
    },
    products: {
      value: [],
    }
  },
};