import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SideNavItem } from "./side-nav-item";
import Tooltip from "src/components/tooltip";

interface Item {
    disabled?: boolean;
    disabledMessage?: string;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    title: string;
    hide?: boolean;
}

const renderItems = ({
    depth = 0,
    items,
    pathname,
    desktopNavOpen,
}: {
    depth?: number;
    items: Item[];
    pathname?: string | null;
    desktopNavOpen: () => void;
}): JSX.Element[] =>
    items.reduce(
        (acc: JSX.Element[], item) =>
            reduceChildRoutes({
                acc,
                depth,
                item,
                pathname,
                desktopNavOpen,
            }),
        []
    );

const reduceChildRoutes = ({
    acc,
    depth,
    item,
    pathname,
    desktopNavOpen,
}: {
    acc: JSX.Element[];
    depth: number;
    item: Item;
    pathname?: string | null;
    desktopNavOpen: () => void;
}): Array<JSX.Element> => {
    const checkPath = !!(item.path && pathname);
    const rootPath = checkPath ? pathname?.split("/")[2] : false;
    const itemRootPath = item.path?.split("/")[1];
    const exactMatch = rootPath ? rootPath === itemRootPath : false;

    if (!item.hide) {
        // Always prepare the SideNavItem component
        const sideNavItem = (
            <SideNavItem
                active={exactMatch}
                depth={depth}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={"side-nav-item-" + item.title}
                label={item.label}
                path={item.path}
                title={item.title}
                desktopNavOpen={desktopNavOpen}
                disabledMessage={item.disabledMessage}
                item={item}
            />
        );

        // Conditionally wrap SideNavItem with Tooltip if disabled and disabledMessage is provided
        const element =
            item.disabled && item.disabledMessage ? (
                <Tooltip
                    key={"side-nav-item-with-tooltip-" + item.title}
                    title={item.disabledMessage}
                    placement='right-start'
                    componentsProps={{
                        popper: { modifiers: [{ name: "offset", options: { offset: [0, -20] } }] },
                    }}
                >
                    <span>{sideNavItem}</span>
                </Tooltip>
            ) : (
                sideNavItem
            );

        acc.push(element);
    }

    return acc;
};

interface SideNavSectionProps {
    items?: Item[];
    pathname?: string | null;
    subheader?: string;
    desktopNavOpen: () => void;
}

export const SideNavSection: FC<SideNavSectionProps> = (props) => {
    const { items = [], pathname, desktopNavOpen, subheader = "", ...other } = props;

    return (
        <Stack
            component='ul'
            spacing={0.5}
            sx={{
                listStyle: "none",
                m: 0,
                p: 0,
            }}
            {...other}
        >
            {subheader && (
                <Box
                    component='li'
                    sx={{
                        color: "var(--nav-section-title-color)",
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: 1.66,
                        mb: 1,
                        ml: 1,
                        textTransform: "uppercase",
                    }}
                >
                    {subheader}
                </Box>
            )}
            {renderItems({ items, pathname, desktopNavOpen })}
        </Stack>
    );
};

SideNavSection.propTypes = {
    items: PropTypes.array,
    pathname: PropTypes.string,
    subheader: PropTypes.string,
};
