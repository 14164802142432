import React, { useMemo } from "react";
import { Link } from "@mui/material";
import Breadcrumbs from "src/components/bread-crumbs";
import { usePathname } from "src/hooks/use-pathname";
import { Item, Section, useSections } from "../config";

interface BreadcrumbsNavProps {
    // Add any additional props you need
}

const BreadcrumbsNav: React.FC<BreadcrumbsNavProps> = () => {
    const sections = useSections();
    const pathname = usePathname();

    const getActiveSubItems = (items: Item[], pathname: string): Item => {
        return items.find((item) => item.path === pathname || pathname.includes(item.path || "")) || ({} as Item);
    };

    function getActiveSection(array: Section[], pathname: string): Item {
        const rootPath = pathname.split("/")[2] || "/";
        for (const entry of array) {
            for (const item of entry.items) {
                if (item.title.toLowerCase() === rootPath.toLowerCase() && item.items) {
                    return item;
                }
                if (item.title.toLowerCase() === "home" && rootPath.toLowerCase() === "/" && item.items) {
                    return item;
                }
            }
        }
        return {} as Item;
    }

    const activeSection: Item = useMemo(() => getActiveSection(sections, pathname), [sections, pathname]);
    const activeSubItem = useMemo(
        () => getActiveSubItems((activeSection as Item).items || [], pathname),
        [activeSection, pathname]
    );

    // Create a breadcrumbs array from the active section and sub item
    const breadcrumbs = [
        activeSection && activeSection.title && (
            <Link
                key={activeSection.title}
                underline='none'
                color='text.secondary'
                sx={{
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    lineHeight: "1.75",
                }}
            >
                {activeSection.title}
            </Link>
        ),
        activeSubItem && activeSubItem.title && (
            <Link
                key={activeSubItem.title}
                underline='none'
                color='primary'
                sx={{
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    lineHeight: "1.75",
                }}
            >
                {activeSubItem.title}
            </Link>
        ),
    ].filter(Boolean);

    return <Breadcrumbs>{breadcrumbs}</Breadcrumbs>;
};

export default BreadcrumbsNav;
