import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet, Navigate } from "react-router-dom";

import { useAuth } from 'src/hooks/use-auth';
import { Layout as AuthClassicLayout } from "src/layouts/auth/layout";

const ForgotPasswordPage = lazy(() => import("src/pages/auth/forgot-password"));
const NewPasswordPage = lazy(() => import("src/pages/auth/new-password"));
const LoginPage = lazy(() => import("src/pages/auth/login"));

const AuthRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
	const { isAuthenticated } = useAuth();
  
	if (!isAuthenticated) {
	  return <>{element}</>;
	}
  
	return <Navigate to="/" />;
  };
  

export const authRoutes: RouteObject[] = [
	{
		path: "auth",
		children: [
			{
				element: (
					<AuthClassicLayout>
						<Suspense>
							<Outlet />
						</Suspense>
					</AuthClassicLayout>
				),
				children: [
					{
						path: "forgot-password",
						element: <AuthRoute element={<ForgotPasswordPage />} />,
					},
					{
						path: "login",
						element: <AuthRoute element={<LoginPage />}/>,
					},
				],
			},
		],
	},
	{
		path: "password",
		children: [
			{
				element: (
					<AuthClassicLayout>
						<Suspense>
							<Outlet />
						</Suspense>
					</AuthClassicLayout>
				),
				children: [
					{
						path: "reset/:token",
						element: <NewPasswordPage />,
					},
				],
			},
		],
	},
];