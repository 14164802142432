import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

type NavigateOptions = object;

type URLParams = {
    team?: string;
};

interface Router {
    /**
     * Navigate to the previous history entry.
     */
    back(): void;

    /**
     * Navigate to the next history entry.
     */
    forward(): void;

    /**
     * Refresh the current page.
     */
    refresh(): void;

    /**
     * Navigate to the provided href.
     * Pushes a new history entry.
     */
    push(href: string, options?: NavigateOptions): void;

    /**
     * Navigate to the provided href.
     * Replaces the current history entry.
     */
    replace(href: string, options?: NavigateOptions): void;

    /**
     * Prefetch the provided href.
     */
    prefetch(href: string): void;

    /**
     * Update the team in the URL
     */
    updateTeamRoute(key: string, team: string): void;
}

/**
 * This is a wrapper over `react-router/useNavigate` hook.
 * We use this to help us maintain consistency between CRA and Next.js
 */
export const useRouter = (): Router => {
    const navigate = useNavigate();
    const { team } = useParams();

    return useMemo(() => {
        return {
            back: () => navigate(-1),
            forward: () => navigate(1),
            refresh: () => navigate(0),
            push: (href: string, options?: NavigateOptions) => navigate(`/${team}${href}`),
            replace: (href: string, options?: NavigateOptions) => navigate(href, { replace: true }),
            prefetch: (href: string) => {},
            updateTeamRoute: (key: keyof URLParams, team: string) => {
                const newPath = `/${team}`;

                // Use replace to update the URL without creating a new entry
                navigate(newPath, { replace: true });
            },
        };
    }, [navigate]);
};
